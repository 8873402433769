<template>
  <div style="background-color: white" class="mt-n15">
    <v-app-bar app :flat="flat" :class="{ expand: flat }" class="navbar navbar-expand-lg navbar-light bg-white sticky" data-offset="500">
      <v-app-bar-nav-icon v-if="xsOnly"></v-app-bar-nav-icon>
      <v-toolbar-title>Jinglee Classrooms</v-toolbar-title>
      <v-spacer></v-spacer>
      <div>
        <v-btn text @click="$vuetify.goTo('#features')" v-if="!$vuetify.breakpoint.smAndDown">
          <span class="mr-2 black--text">Features</span>
        </v-btn>
        <v-btn text @click="$vuetify.goTo('#pricing')" v-if="!$vuetify.breakpoint.smAndDown">
          <span class="mr-2 black--text">Pricing</span>
        </v-btn>
        <v-btn outlined rounded @click="$router.push('/register')">
          <span class="mr-2 black--text">
            <v-icon>mdi-account-circle</v-icon>
            Login
          </span>
        </v-btn>
      </div>
    </v-app-bar>

    <v-container class="container">
      <v-row class="d-flex align-center">
        <v-col cols="12" sm="6" class="order-sm-2">
          <v-img height="600" src="@/views/PromotionPage/assets/image_1.png" alt=""></v-img>
        </v-col>
        <v-col cols="12" sm="6">
          <h1 class="mb-4">We Work For Your Ideas</h1>
          <p class="mb-5">Ignite the most powerfull growth engine you have ever built for your company</p>
          <v-btn rounded class="primary">Watch Video <span> <v-icon>mdi-play</v-icon></span></v-btn>
        </v-col>
      </v-row>
    </v-container>

    <section class="showcase">
      <v-container class="container-fluid p-0">
          <v-row class="my-0 py-0">
            <v-col class="col-lg-6 order-lg-2 text-white showcase-img">
              <v-img src="@/views/PromotionPage/assets/bg-showcase-1.jpg"></v-img>
            </v-col>
            <v-col class="col-lg-6 order-lg-1 my-auto showcase-text">
                <h2>Fully Responsive Design</h2>
                <p class="lead mb-0">When you use a theme created by Start Bootstrap, you know that the theme will look great on any device, whether it's a phone, tablet, or desktop the page will behave responsively!</p>
            </v-col>
          </v-row>
          <v-row class="my-0 py-0">
            <v-col class="col-lg-6 text-white showcase-img">
              <v-img src="@/views/PromotionPage/assets/bg-showcase-2.jpg"></v-img>
            </v-col>
            <v-col class="col-lg-6 my-auto showcase-text">
                <h2>Updated For Bootstrap 5</h2>
                <p class="lead mb-0">Newly improved, and full of great utility classes, Bootstrap 5 is leading the way in mobile responsive web development! All of the themes on Start Bootstrap are now using Bootstrap 5!</p>
            </v-col>
          </v-row>
          <v-row class="my-0 py-0">
            <v-col class="col-lg-6 order-lg-2 text-white showcase-img">
              <v-img src="@/views/PromotionPage/assets/bg-showcase-3.jpg"></v-img>
            </v-col>
            <v-col class="col-lg-6 order-lg-1 my-auto showcase-text">
                <h2>Easy to Use & Customize</h2>
                <p class="lead mb-0">Landing Page is just HTML and CSS with a splash of SCSS for users who demand some deeper customization options. Out of the box, just add your content and images, and your new landing page will be ready to go!</p>
            </v-col>
          </v-row>
      </v-container>
    </section>
    <section style="background-color: #f8f9fa"  class="py-10">
      <v-container>
          <div class="text-center my-5">
              <h2>What people are saying...</h2>
              <h3>Lorem ipsum dolor sit amet consectetur.</h3>
          </div>
          <v-row>
            <v-col class="col-lg-4">
                <div class="team-member">
                  <v-img class="mx-auto rounded-circle" src="@/views/PromotionPage/assets/1.jpg" alt="..." height="250" width="250" />
                  <h4 class="text-center mt-7">Parveen Anand</h4>
                  <p class="text-center">"This is fantastic! Thanks so much guys!"</p>
                </div>
            </v-col>
            <v-col class="col-lg-4">
                <div class="team-member">
                  <v-img class="mx-auto rounded-circle" src="@/views/PromotionPage/assets/2.jpg" alt="..." height="250" width="250" />
                  <h4 class="text-center mt-7">Diana Petersen</h4>
                  <p class="text-center">"Thanks so much for making these free resources available to us!"</p>
                </div>
            </v-col>
            <v-col class="col-lg-4">
                <div class="team-member">
                  <v-img class="mx-auto rounded-circle" src="@/views/PromotionPage/assets/3.jpg" alt="..." height="250" width="250" />
                  <h4 class="text-center mt-7">Larry Parker</h4>
                  <p class="text-center">"I've been using it to create lots of super nice features."</p>
                </div>
            </v-col>
          </v-row>
      </v-container>
    </section>
    
     <section>
      <v-container class="my-6">
        <div class="text-center wow fadeInUp">
          <div class="subhead">Pricing Plan</div>
          <h2 class="title-section">Choose plan the right for you</h2>
          <div class="divider mx-auto"></div>
        </div>
        <v-row class="mt-5">
          <v-col cols="12" sm="4" class="py-3 wow zoomIn"  v-for="(i, index) in 3" :key="index">
            <v-card class="text-center" outlined rounded="500">
              <v-card-title class="d-flex justify-center"> Basic </v-card-title>
              <v-card-title class="d-flex justify-center pt-0 my-0"> $ 39.99 Per Month </v-card-title>
              <v-card-text class="body">
                <p>25 Analytics <span class="suffix">Campaign</span></p>
                <p>1,300 Change <span class="suffix">Keywords</span></p>
                <p>Social Media <span class="suffix">Reviews</span></p>
                <p>1 Free <span class="suffix">Optimization</span></p>
                <p>24/7 <span class="suffix">Support</span></p>
              </v-card-text>
              <!-- <v-card-actions class="d-flex justify-center">
                <v-btn color="primary" outlined>Subscribe</v-btn>
              </v-card-actions> -->
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="text-white text-center my-10">
      <v-container>
        <div class="d-flex justify-center">
            <div class="col-xl-6">
              <h2 class="my-4">Ready to get started? Sign up now!</h2>
              <v-layout  class="pt-5 d-flex align-center justify-center">
                  <v-flex class="d-flex align-center justify-center" lg8>
                      <v-text-field hide-details rounded outlined placeholder="Email Address" />
                      <v-btn class="mx-2" dark>Submit</v-btn>
                  </v-flex>
              </v-layout>
            </div>
        </div>
      </v-container>
    </section>
    <v-footer>
      <v-container>
        <v-row class="row d-flex justify-space-between">
            <v-col cols="12" sm="4" class="d-flex align-center">Copyright &copy; Your Website 2021</v-col>
            <v-col cols="12" sm="4" class="col-lg-4 d-flex justify-end align-center">
                <a class="link-dark text-decoration-none me-3" @click="$router.push('/privacy-policy')">Privacy Policy</a>
                <a class="link-dark text-decoration-none" href="#!">Terms of Use</a>
            </v-col>
        </v-row>
       </v-container>
    </v-footer>
  </div>
</template>
<script>
export default {
  data () {
    return {
      flat: true,
      color: 'transparent'
    }
  },
  computed: {
    isXs () {
      return this.$vuetify.breakpoint.xs
    }
  },
  watch: {
    fab (value) {
      this.color = value ? '#ffffff' : 'transparent'
      this.flat = Boolean(!value)
    }
  },
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 60
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  }
}
</script>